<template>
  <div><castell-edit></castell-edit></div>
</template>

<script>
import CastellEdit from "../components/CastellEdit-Component.vue";
export default {
  components: { CastellEdit },
};
</script>
