<template>
  <div><event-edit></event-edit></div>
</template>

<script>
import EventEdit from "../components/EventEdit-Component.vue";

export default {
  components: { EventEdit },
};
</script>
